<template>
  <el-dialog
          :title="title"
          :close-on-click-modal="false"
          v-dialogDrag
          :visible.sync="visible">
    <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" @keyup.enter.native="doSubmit()"
             label-width="80px" v-loading="loading" :class="method==='view'?'readonly':''"
             :disabled="method==='view'" @submit.native.prevent>
      <el-row :gutter="15">
        <el-col :span="12">
          <el-form-item label="权限名称" prop="fieldName">
            <el-input v-model="inputForm.fieldName" placeholder="权限名称" maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级权限" prop="parentId" >
            <SelectTree
                    ref="officeTree"
                    :props="{
                value: 'id',             // ID字段名
                label: 'fieldName',         // 显示名称
                children: 'children'    // 子级字段名
              }"
                    :data="officeList"
                    :value="inputForm.parentId"
                    :clearable="true"
                    :accordion="true"
                    v-if="visible"
                    @getValue="(value) => {inputForm.parentId=value}"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="查询字段" prop="field">
            <el-select v-model="inputForm.field" placeholder="请选择" style="width: 100%;">
              <el-option
                      v-for="item in fieldNameOptions"
                      :key="item.field"
                      :label="item.comment"
                      :value="item.field">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="查询条件" prop="fieldCondition">
            <el-select v-model="inputForm.fieldCondition" placeholder="请选择" style="width: 100%;">
              <el-option
                      v-for="item in this.$dictUtils.getDictList('senior_retrieve')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="查询值" prop="fieldValue">
            <el-input v-model="inputForm.fieldValue" placeholder="查询值" maxlength="20"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="排序号" prop="sort">
            <el-input-number style="width:100%" :step="1" :min="0" :max="99" v-model="inputForm.sort"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" v-if="method != 'view'" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import SelectTree from '@/components/treeSelect/treeSelect.vue'

  export default {
    data() {
      return {
        officeList:[],
        isShowList:[
          {
            value: 0,
            label:"是"
          },
          {
            value: 1,
            label:"否"
          }
        ],
        title: '',
        method: '',
        officeParentTreeData: [],
        areaTreeData: [],
        visible: false,
        loading: false,
        inputForm: {
          id: '0',
          field: '',
          fieldName:"",
          parentId:"",
          sort: 1,  // 排序号
          fieldCondition: '', // 机构编码
          fieldValue: '', // 机构类型（1：公司；2：部门；3：小组）
        },
        fieldNameOptions:[],
        dataRule: {
          field: [
            {required: true, message: '查询字段不能为空', trigger: 'blur'}
          ],
          fieldName: [
            {required: true, message: '名称不能为空', trigger: 'blur'}
          ],
          fieldCondition: [
            {required: true, message: '查询条件不能为空', trigger: 'blur'}
          ],
          fieldValue: [
            {required: true, message: '查询值不能为空', trigger: 'blur'}
          ],
        }
      }
    },
    components: {
      SelectTree
    },
    methods: {
      init(method, obj, row) {
        this.method = method
        if (method === 'add') {
          this.title = '新增数据权限'
        } else if (method === 'addChild') {
          this.title = '添加下级数据权限'
        } else if (method === 'edit') {
          this.title = '修改数据权限'
        } else if (method === 'view') {
          this.title = '查看数据权限'
        }
        this.$axios(this.api.catalogue.tableDate, {flag:"music_exhibition.file_collect"}, 'get').then(data => {
          if (data && data.status) {
            this.fieldNameOptions = data.data
          }
        })
        this.$axios(this.api.common.dataRoleList, {}, 'get').then(data => {
          this.officeList = data.data
          this.inputForm.parentId = ''
        }).then(() => {
          this.visible = true
          this.loading = false
          this.$nextTick(() => {
            this.$refs.officeTree.clearHandle()
            this.$refs['inputForm'].resetFields()
            this.inputForm.parentId = obj.parent.id
          })
        }).then(() => {
          if (method === 'edit' || method === 'view') { // 修改或者查看
            this.inputForm = this.recover(this.inputForm, row)
          }
        })
      },
      // 表单提交
      doSubmit() {
        this.$refs['inputForm'].validate((valid) => {
          if (valid) {
            this.loading = true
            let api = '', type = '';
            if (this.method === 'add' || this.method === 'addChild') {  // 新增
              this.inputForm.id = ''
              api = this.api.common.dataRoleSave;
              type = 'post';
            } else if (this.method === 'edit') {  // 修改
              api = this.api.common.dataRoleUpdateById
              type = 'put';
            }
            if (!this.inputForm.parentId) {
              this.inputForm.parentId = '0'
            }
            this.$axios(api, this.inputForm, type).then(data => {
              if (data.status) {
                this.$message.success(data.msg)
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        })
      }
    }
  }
</script>
