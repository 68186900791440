<template>
    <div class="page">
        <el-form size="small" :inline="true"  class="query-form" ref="searchForm" :model="searchForm"  @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="name">
                <el-input size="small" maxlength="66" v-model="searchForm.name"   placeholder="权限名称"   clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button  type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <vxe-toolbar :refresh="{query: refreshList}" export print custom>
                <template #buttons>
                    <el-row>
                        <el-button v-if="hasPermission('sys:data:add')" type="primary" icon="el-icon-plus" size="small" @click="add()">新增</el-button>
                        <el-button v-if="hasPermission('sys:data:del')" :disabled="$refs.xTree && $refs.xTree.getCheckboxRecords().length === 0" type="danger" size="small" icon="el-icon-delete" @click="del()">删除</el-button>
                    </el-row>
                </template>
            </vxe-toolbar>
            <div style="height: calc(100% - 50px);">
                <vxe-table
                        resizable
                        ref="xTree"
                        border="inner"
                        auto-resize
                        height="auto"
                        row-id="id"
                        size="small"
                        show-header-overflow
                        show-overflow
                        highlight-hover-row
                        :print-config="{}"
                        :export-config="{}"
                        :tree-config="{expandAll: true}"
                        :loading="loading"
                        :checkbox-config="{labelField: ''}"
                        :data="dataList">
                    <vxe-table-column type="checkbox" width="40px"> </vxe-table-column>
                    <vxe-table-column  title="权限名称"  field="fieldName"  align="left" tree-node >
                        <template slot-scope="scope">
                            <el-link  type="primary" :underline="false" v-if="hasPermission('sys:data:edit')" @click="edit(scope.row)">{{scope.row.fieldName}}</el-link>
                            <el-link  v-else-if="hasPermission('sys:data:view')" type="primary" :underline="false"   @click="view(scope.row)">{{scope.row.fieldName}}</el-link>
                            <span v-else>{{scope.row.fieldName}}</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column  title="查询条件" field="fieldCondition" align="center">
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("senior_retrieve", scope.row.fieldCondition)}}
                        </template>
                    </vxe-table-column>
                    <vxe-table-column  title="查询值" field="fieldValue" align="center"></vxe-table-column>
                    <vxe-table-column title="操作" width="300px" fixed="right" align="center">
                        <template slot-scope="scope">
                            <el-button  v-if="hasPermission('sys:data:view')" type="text" icon="el-icon-view" size="small" @click="view(scope.row)">查看</el-button>
                            <el-button  v-if="hasPermission('sys:data:edit')" type="text" icon="el-icon-edit" size="small" @click="edit(scope.row)">修改</el-button>
                            <el-button  v-if="hasPermission('sys:data:del')" type="text" icon="el-icon-delete" size="small" @click="del(scope.row.id)">删除</el-button>
                            <el-button  v-if="hasPermission('sys:data:add')" type="text" icon="el-icon-circle-plus-outline" size="small" @click="addChild(scope.row)">添加下级</el-button>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <OfficeForm ref="officeForm" @refreshDataList="refreshList"></OfficeForm>
    </div>
</template>

<script>
    import OfficeForm from './DateForm'
    import XEUtils from 'xe-utils'
    export default {
        data () {
            return {
                loading: false,
                searchForm: {
                    name: ''
                },
                dataList: []
            }
        },
        components: {
            OfficeForm
        },
        activated () {
            this.refreshList()
        },
        methods: {
            // 获取数据列表
            refreshList () {
                this.loading = true
                this.$axios(this.api.common.dataRoleList, {}, 'get').then(data => {
                    if(data.status && data.data){
                        this.dataList = data.data
                        this.handleSearch()
                        this.loading = false
                    }
                })
            },
            handleSearch () {
                let filterName = XEUtils.toValueString(this.searchForm.name).trim()
                if (filterName) {
                    let options = { children: 'children' }
                    let searchProps = ['fieldName']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(filterName) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
            },
            resetSearch () {
                this.$refs.searchForm.resetFields()
                this.refreshList()
            },
            // 新增下级
            addChild (row) {
                this.$refs.officeForm.init('addChild', {id: '', parent: {id: row.id}})
            },
            // 新增
            add () {
                this.$refs.officeForm.init('add', {id: '', parent: {id: '', name: ''}})
            },
            // 修改
            edit (row) {
                this.$refs.officeForm.init('edit', {id: row.id, parent: {id: '', name: ''}},row)
            },
            // 查看
            view (row) {
                this.$refs.officeForm.init('view', {id: row.id, parent: {id: '', name: ''}},row)
            },
            // 删除
            del (id) {
                let ids = id || this.$refs.xTree.getCheckboxRecords().map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除该记录吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.common.dataRoleRemoveById, {ids: ids}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            }
        }
    }
</script>
